import dcmjs from "dcmjs";
import dicomImageLoader from "@cornerstonejs/dicom-image-loader";

class DICOMFileLoader {
  addFile(file) {
    return dicomImageLoader.wadouri.fileManager.add(file);
  }

  loadFile(imageId: string): Promise<ArrayBuffer> {
    return dicomImageLoader.wadouri.loadFileRequest(imageId);
  }

  getDataset(image, imageId) {
    const dicomData = dcmjs.data.DicomMessage.readFile(image);
    const dataset = dcmjs.data.DicomMetaDictionary.naturalizeDataset(
      dicomData.dict,
    );
    dataset.url = imageId;
    dataset._meta = dcmjs.data.DicomMetaDictionary.namifyDataset(
      dicomData.meta,
    );
    dataset.AvailableTransferSyntaxUID =
      dataset.AvailableTransferSyntaxUID ||
      dataset._meta.TransferSyntaxUID?.Value?.[0];
    return dataset;
  }

  async getStudies(): Promise<any[]> {
    return [];
  }
}

export default DICOMFileLoader;
