import { api } from "dicomweb-client";

const storeInstances = async (datasets, request?, token?: string) => {
  const dicomWebConfig = {
    wadoRoot: process.env.VITE_PUBLIC_WADO_URL,
    singlepart: "pdf,video",
  };
  const wadoDicomWebClient = new api.DICOMwebClient({
    url: dicomWebConfig.wadoRoot,
    singlepart: dicomWebConfig.singlepart,
    headers: { Authorization: "Bearer " + token },
    requestHooks: [
      (error) => {
        if (error) {
          console.error(error);
        }
      },
    ],
  });
  return await wadoDicomWebClient.storeInstances({
    datasets,
    request,
  });
};

export default storeInstances;
