import DICOMFileLoader from "./dicomFileLoader";
import { DicomMetadataStore } from "@ohif/core";

const cache = new Map();

const processFile = async (file) => {
  try {
    const fileLoaderService = new DICOMFileLoader();
    const imageId = fileLoaderService.addFile(file);
    const image = await fileLoaderService.loadFile(imageId);
    cache.set(imageId, image);
    const dicomJSONDataset = await fileLoaderService.getDataset(image, imageId);
    DicomMetadataStore.addInstance(dicomJSONDataset);
  } catch (error) {
    console.log(
      error.name,
      ":Error when trying to load and process local files:",
      error.message,
    );
  }
};

export default async function filesToStudies(files) {
  const processFilesPromises = files.map(processFile);
  await Promise.all(processFilesPromises);
  const studyUIDs = DicomMetadataStore.getStudyInstanceUIDs();
  return studyUIDs.map((uid) => {
    const studyMetadata = DicomMetadataStore.getStudy(uid);
    const seriesMetadata = studyMetadata.series[0];
    return {
      ...studyMetadata,
      ...(seriesMetadata.instances[0] || {}),
      series: studyMetadata.series.map((s) => ({
        ...s,
        ...s.instances[0],
        instances: s.instances.map((i) => ({
          ...i,
          image: cache.get(i.url),
          imageId: i.url,
        })),
      })),
    };
  });
}
