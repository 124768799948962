import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { useAuth } from "@/hooks/useAuth";
import { isValidEmail, isValidPhone } from "@/utils/validation";

export default function LoginPage() {
  const { login } = useAuth();
  const [validationError, setValidationError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    step: 1,
    message: "",
    success: false,
  });

  const [identifier, setIdentifier] = useState("");

  const handleLogin = async (formData: FormData): Promise<void> => {
    setIsLoading(true);
    try {
      const result = await login(formData);
      setState(result);
    } catch (error) {
      console.error(error);
      setState((prev) => ({
        ...prev,
        message: "Login failed",
        success: false,
      }));
    } finally {
      setIsLoading(false);
      setIdentifier("");
    }
  };

  const handleSubmit = async (formData: FormData) => {
    if (state.step === 1) {

      setValidationError("");

      // Validate input
      if (!identifier) {
        setValidationError("Please enter an email or phone number");
        return;
      }

      if (!isValidEmail(identifier) && !isValidPhone(identifier)) {
        setValidationError(
          "Please enter a valid email or phone number (+14444444444)"
        );
        return;
      }
    }

    void handleLogin(formData);
  };

  return (
    <div className="flex min-h-screen items-center justify-center">
      <Card className="w-[500px] bg-container border-none">
        <CardHeader>
          <div className="flex justify-center mb-4">
            <img src="nexus_logo.png" alt="NexusMD" className="h-12" />
          </div>
          <CardTitle className="text-white">DCM-Uploader</CardTitle>
        </CardHeader>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(new FormData(e.currentTarget));
          }}
        >
          <CardContent>
            {state.step === 1 ? (
              <div className="grid w-full items-center gap-4">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="identifier" className="text-white">
                    Email or Phone
                  </Label>
                  <Input
                    id="identifier"
                    name="identifier"
                    value={identifier}
                    onChange={(e) => setIdentifier(e.target.value)}
                    placeholder="Email or phone (+14444444444)"
                    required
                    className="bg-container text-white placeholder-[#9DA27A]"
                  />
                  {validationError && (
                    <span className="text-sm text-red-500">{validationError}</span>
                  )}
                </div>
              </div>
            ) : (
              <div className="grid w-full items-center gap-4">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="code" className="text-white">
                    Verification Code
                  </Label>
                  <Input
                    id="code"
                    name="code"
                    value={identifier}
                    onChange={(e) => setIdentifier(e.target.value)}
                    placeholder="Enter the code sent to you"
                    required
                    className="bg-container text-white placeholder-[#9DA27A]"
                  />
                </div>
              </div>
            )}
          </CardContent>
          <CardFooter>
            <Button
              className="w-full bg-[#28929C] text-white rounded-full"
              type="submit"
              disabled={isLoading}
            >
              {isLoading
                ? "Loading..."
                : state.step === 1
                ? "Send Code"
                : "Login"}
            </Button>
          </CardFooter>
        </form>
        {state.message && (
          <div
            className={`p-4 text-center ${
              state.success ? "text-green-600" : "text-red-600"
            }`}
          >
            {state.message}
          </div>
        )}
      </Card>
    </div>
  );
}
