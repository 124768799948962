import { AuthProvider } from "@/AuthProvider";
import { useAuth } from "@/hooks/useAuth";
import React from "react";
import Dashboard from "./dashboard";
import Login from "./login";

function MainComponent() {
  const { isAuthenticated } = useAuth();

  return (
    <div className="w-full min-h-screen bg-background">
      {isAuthenticated ? <Dashboard /> : <Login />}
    </div>
  );
}

export default function Component() {
  return (
    <AuthProvider>
      <MainComponent />
    </AuthProvider>
  );
}
