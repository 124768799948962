import React from "react";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/hooks/useAuth";

export function Toolbar() {
  const { logout } = useAuth();

  return (
    <div className="flex justify-between items-center bg-container p-4 w-full">
      <img src="nexus_logo.png" alt="NexusMD" className="h-12" />
      
      <Button
        type="button"
        onClick={() => logout()}
        className="text-white border border-white bg-container rounded-10 px-4 py-2"
      >
        Logout
      </Button>
    </div>
  );
}
