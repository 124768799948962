import React, { useState, useEffect, ReactNode } from "react";
import { AuthContext } from "./context/AuthContext";
import { LoginResponse, VerifyResponse } from "./types/AuthContextType";

function isValidEmail(email: string): boolean {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
}

let cachedIdentifier = "";

export function AuthProvider({ children }: { children: ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    setIsAuthenticated(!!token);
  }, [token]);

  async function login(formData: FormData): Promise<LoginResponse> {
    const identifier = formData.get("identifier") as string;
    const code = formData.get("code") as string;
    cachedIdentifier = identifier || cachedIdentifier;

    if (!code) {
      // First step: send code
      const response = await fetch(
        `${process.env.VITE_PUBLIC_API_URL}/otp/request?` +
          new URLSearchParams(
            isValidEmail(identifier)
              ? { email: identifier }
              : { phone_number: identifier },
          ).toString(),
        {
          method: "POST",
        },
      );

      if (!response.ok) {
        setToken(null);
        return { success: false, message: "Invalid identifier", step: 1 };
      }

      console.log(`Sending code to ${identifier}`);
      return { success: true, message: "Code sent successfully", step: 2 };
    } else {
      // Second step: verify code
      console.log(`Verifying code for ${identifier}`);

      try {
        const response = await fetch(
          `${process.env.VITE_PUBLIC_API_URL}/otp/verify?` +
            new URLSearchParams(
              isValidEmail(cachedIdentifier)
                ? { otp: code, email: cachedIdentifier }
                : { otp: code, phone_number: cachedIdentifier },
            ).toString(),
          {
            method: "GET",
          },
        );

        if (!response.ok) {
          setToken(null);
          throw new Error("Verification failed");
        }

        const data: VerifyResponse = await response.json();
        setToken(data.token);
        setIsAuthenticated(true);

        return {
          success: true,
          message: "Login successful",
          step: 3,
        };
      } catch (error) {
        console.error("Error verifying code:", error);
        return { success: false, message: "Invalid code", step: 2 };
      }
    }
  }

  const logout = () => {
    setToken(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, token }}>
      {children}
    </AuthContext.Provider>
  );
}
