import { useState } from "react";
import { ChevronDown, ChevronRight, Upload, Check } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/hooks/use-toast";
import { Toaster } from "@/components/ui/toaster";
import { Toolbar } from "@/components/ui/toolbar";
import { utils } from "@ohif/core";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Card,
  CardFooter,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useAuth } from "@/hooks/useAuth";
import filesToStudies from "./utils/filesToStudies";
import * as React from "react";
import storeInstances from "./utils/storeInstances";
import * as cornerstone from '@cornerstonejs/core';
import { init as coreInit, Enums } from '@cornerstonejs/core';
import { init as dicomImageLoaderInit } from '@cornerstonejs/dicom-image-loader';

type DicomImage = ArrayBuffer;

type Item = {
  StudyInstanceUID: string;
  StudyDate: string;
  StudyDescription: string;
  Modality: string;
  PatientName: string;
  PatientBirthDate: string;
  AccessionNumber?: string;
  series?: Array<{
    SeriesInstanceUID: string;
    SeriesDescription: string;
    Modality: string;
    instances: Array<{
      image: DicomImage;
    }>;
  }>;
};

type ImportedFile = {
  name: string;
  isStored: boolean;
  ref?: React.RefObject<HTMLLIElement>;
};

declare module "react" {
  interface InputHTMLAttributes<T> extends React.HTMLAttributes<T> {
    webkitdirectory?: string;
    directory?: string;
  }
}

// Add this helper function outside the component
const handleFilesImport = async (
  files: FileList | null,
  setStudies: React.Dispatch<React.SetStateAction<Item[]>>,
  setImportedFiles: React.Dispatch<React.SetStateAction<ImportedFile[]>>,
  toast: any
) => {
  if (!files) return;
  
  const studies = await filesToStudies(Array.from(files));
  console.debug("studies", studies);
  setStudies(studies);
  
  setImportedFiles((prev) => {
    const newFiles = [...prev];
    const existingFileNames = new Set(prev.map(file => file.name));
    
    // Only add files that don't already exist
    const filesToAdd = Array.from(files)
      .filter(file => !existingFileNames.has(file.name))
      .map((file) => ({
        name: file.name,
        isStored: false,
        ref: React.createRef<HTMLLIElement>(),
      }));
    
    if (filesToAdd.length < files.length) {
      toast({
        variant: "default",
        title: "Duplicate Files",
        description: "Some files were skipped as they were already imported.",
      });
    }
    
    newFiles.push(...filesToAdd);
    return newFiles;
  });
};

export default function Component() {
  const { toast } = useToast();
  const { token } = useAuth();
  const [studies, setStudies] = useState<Item[]>([]);

  const [instancesToBeStored, setInstancesToBeStored] = useState<DicomImage[]>(
    []
  );
  const [isUploading, setIsUploading] = useState(false);

  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [importedFiles, setImportedFiles] = useState<ImportedFile[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const toggleRow = (id: string) => {
    setExpandedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  const handleFileImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstancesToBeStored([]);
    setStudies([]);
    await handleFilesImport(event.target.files, setStudies, setImportedFiles, toast);
  };

  const handleDirectoryImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstancesToBeStored([]);
    setStudies([]);
    await handleFilesImport(event.target.files, setStudies, setImportedFiles, toast);
  };

  const toggleItemSelection = (id: string) => {
    setSelectedItems((prev) =>
      prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
    );
  };

  const isItemSelected = (id: string) => selectedItems.includes(id);

  const renderTableRows = (items: Item[]) => {
    return items.map((item) => (
      <React.Fragment key={item.StudyInstanceUID}>
        <TableRow className="group">
          <TableCell className="w-[30px] p-0">
            {item.series && (
              <Button
                variant="ghost"
                size="icon"
                className="h-6 w-6 hover:bg-container hover:text-white"
                onClick={() => toggleRow(item.StudyInstanceUID)}
              >
                {expandedRows.includes(item.StudyInstanceUID) ? (
                  <ChevronDown className="h-3 w-3" />
                ) : (
                  <ChevronRight className="h-3 w-3" />
                )}
                <span className="sr-only">Toggle</span>
              </Button>
            )}
          </TableCell>
          <TableCell className="w-[30px] p-2">
            <Checkbox
              id={`checkbox-${item.StudyInstanceUID}`}
              checked={isItemSelected(item.StudyInstanceUID)}
              onCheckedChange={() => toggleItemSelection(item.StudyInstanceUID)}
              className="border-white h-4 w-4"
            />
          </TableCell>
          <TableCell
            className="truncate max-w-[200px]"
            title={item.StudyInstanceUID}
          >
            {item.StudyInstanceUID}
          </TableCell>
          <TableCell className="w-[100px] p-1">
            {item.series && item.series.length > 0 && (
              <div className="w-[80px] h-[80px] bg-black/20 rounded overflow-hidden">
                <div ref={contentRef}></div>
              </div>
            )}
          </TableCell>
          <TableCell className="w-[100px] whitespace-nowrap">{utils.formatDate(item.StudyDate)}</TableCell>
          <TableCell className="w-[150px] truncate">{item.StudyDescription}</TableCell>
          <TableCell className="w-[150px] truncate">
            {utils.formatPN(item.PatientName)}
          </TableCell>
          <TableCell className="w-[100px] whitespace-nowrap">{utils.formatDate(item.PatientBirthDate)}</TableCell>
          <TableCell className="w-[80px] whitespace-nowrap">{item.AccessionNumber}</TableCell>
          <TableCell className="w-[80px] whitespace-nowrap">{item.Modality}</TableCell>
          <TableCell className="w-[80px] whitespace-nowrap">
            {item.series?.reduce((total, series) => total + series.instances.length, 0) || 0}
          </TableCell>
        </TableRow>
        {item.series && expandedRows.includes(item.StudyInstanceUID) && (
          <>
            {item.series.map((child, index) => (
              <React.Fragment key={child.SeriesInstanceUID}>
                {index === 0 && (
                  <TableRow className="bg-background/30 text-xs">
                    <TableCell colSpan={2}></TableCell>
                    <TableCell colSpan={3} className="font-medium">Series UID</TableCell>
                    <TableCell colSpan={3} className="font-medium">Series Description</TableCell>
                    <TableCell colSpan={3} className="font-medium">Modality</TableCell>
                    <TableCell colSpan={3} className="font-medium">Instances</TableCell>
                  </TableRow>
                )}
                <TableRow className="bg-background/50 text-xs">
                  <TableCell colSpan={2}></TableCell>
                  <TableCell
                    colSpan={3}
                    className="truncate"
                    title={child.SeriesInstanceUID}
                  >
                    {child.SeriesInstanceUID}
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    className="truncate"
                    title={child.SeriesDescription}
                  >
                    {child.SeriesDescription}
                  </TableCell>
                  <TableCell colSpan={3} className="truncate">{child.Modality}</TableCell>
                  <TableCell colSpan={3} className="truncate">{child.instances.length}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </>
        )}
      </React.Fragment>
    ));
  };

  const handleUpload = async () => {
    if (instancesToBeStored.length === 0) {
      toast({
        variant: "default",
        title: "No instances to upload",
        description: "Please select some instances to upload",
      });
      return;
    }

    const toast409 = () => toast({
      variant: "destructive",
      title: "Duplicate File",
      description: "This file has already been uploaded.",
    });

    try {
      setIsUploading(true);
      let successCount = 0;  // Track successfully stored instances

      for (let i = 0; i < instancesToBeStored.length; i++) {
        const instance = instancesToBeStored[i];
        try {
          const response = await storeInstances([instance], null, token);
          
          // Handle 409 response
          if (response?.status === 409) {
            toast409();
            continue;
          }

          // Increment success count only for successful uploads
          successCount++;

          setImportedFiles(prev => {
            const newFiles = [...prev];
            if (newFiles[i]) {
              newFiles[i] = {
                ...newFiles[i],
                isStored: true
              };
              setTimeout(() => {
                newFiles[i].ref?.current?.scrollIntoView({ behavior: 'smooth' });
              }, 100);
            }
            return newFiles;
          });
        } catch (error: any) {
          // Handle error with 409 status
          if (error?.response?.status === 409 || error?.status === 409) {
            toast409();
            continue;
          }
          throw error;
        }
      }

      // Only show success message if any files were successfully stored
      if (successCount > 0) {
        toast({
          title: "Success",
          description: `Successfully stored ${successCount} instance${
            successCount === 1 ? "" : "s"
          }`,
        });
      }

      // Notify backend about new studies
      studies.forEach((study) => {
        const studyUid = study.StudyInstanceUID;
        fetch(
          `${process.env.VITE_PUBLIC_API_URL}/inbound_manager/data_inbound_notification/${studyUid}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      });
    } catch (error) {
      console.error("Error uploading instances:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to store instances",
      });
    } finally {
      setIsUploading(false);
    }
  };

  React.useEffect(() => {
    const instances: DicomImage[] = [];
    selectedItems.forEach((id) => {
      const study = studies.find((s) => s.StudyInstanceUID === id);
      if (study) {
        study.series?.forEach((series) => {
          instances.push(...series.instances.map((i) => i.image));
        });
      }
    });
    setInstancesToBeStored(Array.from(new Set(instances)));
  }, [selectedItems, studies]);

  console.debug("Instances to be stored:", instancesToBeStored);

  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const getThumbnail = async (studies) => {
      const init = async () => {
        await coreInit();
        await dicomImageLoaderInit();
      };

      await init();

      if (!contentRef.current) return;

      const element = document.createElement('div');
      element.style.width = '80px';
      element.style.height = '80px';
      contentRef.current.appendChild(element);

      const renderingEngineId = 'myRenderingEngine';
      const renderingEngine = new cornerstone.RenderingEngine(renderingEngineId);

      const viewportId = 'thumbnailViewport';
      const viewportInput = {
        viewportId,
        element,
        type: Enums.ViewportType.STACK,
      };

      renderingEngine.enableElement(viewportInput);

      studies.forEach(async (study) => {
        if (study.series?.[0]?.instances?.[0]?.image) {
          try {
            const lastSeries = study.series[study.series.length - 1];
            const middleIndex = Math.floor(lastSeries.instances.length / 2);
            const imageId = lastSeries.instances[middleIndex].imageId;

            const viewport = renderingEngine.getViewport(viewportId) as any;
            viewport.setStack([imageId], 0);
            viewport.render();

          } catch (error) {
            console.error('Error generating thumbnail:', error);
          }
        }
      });
    };

    getThumbnail(studies);
  }, [studies]);

  return (
    <>
      <Toolbar />
      <Toaster />
      <div className="container mx-auto space-y-6 p-4">
        <Card className="bg-container text-white border-none">
          <CardHeader>
            <CardTitle>DCM Uploader</CardTitle>
            <CardDescription>
              Import files from your local system or a directory
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
              <div className="flex-1">
                <Label htmlFor="file-import">Import Files</Label>
                <Input
                  id="file-import"
                  type="file"
                  multiple
                  onChange={handleFileImport}
                  className="mt-1 bg-container text-white placeholder-[#9DA27A]"
                />
              </div>
              <div className="flex-1">
                <Label htmlFor="directory-import">Import Directory</Label>
                <Input
                  id="directory-import"
                  type="file"
                  webkitdirectory=""
                  directory=""
                  onChange={handleDirectoryImport}
                  className="mt-1 bg-container text-white placeholder-[#9DA27A]"
                />
              </div>
            </div>
            {importedFiles.length > 0 && (
              <div>
                <h3 className="mb-2 text-lg font-semibold">Imported DICOM Files:</h3>
                <ul className="h-40 list-inside list-disc overflow-y-auto border p-2 rounded-md">
                  {importedFiles.map((file, index) => (
                    <li
                      key={index}
                      ref={file.ref}
                      className={`flex items-center space-x-2 py-1 px-2 rounded transition-colors duration-200 mb-1 ${file.isStored ? 'bg-green-500/10' : ''
                        }`}
                    >
                      <span className="flex-1">{file.name}</span>
                      {file.isStored && (
                        <Check className="h-4 w-4 text-green-500" />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </CardContent>
        </Card>
        <Card className="bg-container text-white border-none">
          <CardHeader>
            <CardTitle>Studies</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="rounded-md">
              <Table>
                <TableHeader>
                  <TableRow className="bg-background/30">
                    <TableHead className="w-[30px] p-0 text-white"></TableHead>
                    <TableHead className="w-[30px] p-2 text-white"></TableHead>
                    <TableHead className="text-white">Study UID</TableHead>
                    <TableHead className="w-[60px] text-white">Preview</TableHead>
                    <TableHead className="w-[100px] text-white">Study Date</TableHead>
                    <TableHead className="w-[150px] text-white">Study Description</TableHead>
                    <TableHead className="w-[150px] text-white">Patient Name</TableHead>
                    <TableHead className="w-[100px] text-white">DOB</TableHead>
                    <TableHead className="w-[80px] text-white">Accession</TableHead>
                    <TableHead className="w-[80px] text-white">Modality</TableHead>
                    <TableHead className="w-[80px] text-white">Instances</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>{renderTableRows(studies)}</TableBody>
              </Table>
            </div>
          </CardContent>
          <CardFooter className="flex justify-end">
            <Button onClick={handleUpload} disabled={isUploading}>
              {isUploading ? (
                <>
                  <Upload className="mr-2 h-4 w-4 animate-spin" />
                  Uploading...
                </>
              ) : (
                <>
                  <Upload className="mr-2 h-4 w-4" />
                  Upload
                </>
              )}
            </Button>
          </CardFooter>
        </Card>
      </div>
    </>
  );
}
